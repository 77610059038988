import React from 'react';
import { BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href='https://www.linkedin.com/in/swan-htet-htet-swan/' target="_blank" rel="noreferrer">
        <BsLinkedin />
      </a>
      
    </div>
  </div>
);

export default SocialMedia;